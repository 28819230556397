import { Configuration } from "../types";
import { getAllPlayers } from "./utils";

export const validateConfiguration = (configuration: Configuration): void => {
  const players = getAllPlayers(configuration);

  if (new Set(players.map((player) => player.name)).size !== players.length) {
    throw new Error("Players must be unique");
  }

  if (players.length < 2) throw new Error("At least 2 players are required");

  if (configuration.mode === "3vs3" && !configuration.teamMilieu) {
    throw new Error("Team milieu is required in 3vs3 mode");
  }

  if (configuration.mode === "2vs2" && configuration.teamMilieu) {
    throw new Error("Team milieu is not required in 2vs2 mode");
  }
};
