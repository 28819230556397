import { Wave } from "../types";
import { getPlayingHistory } from "./playing-history";

export function getRanks(waves: Wave[]): Record<string, number> {
  const history = getPlayingHistory(waves);

  const ranks: Map<number, Map<number, string[]>> = new Map<
    number,
    Map<number, string[]>
  >();
  Object.entries(history).forEach(([playerName, history]) => {
    if (!ranks.has(history.gamesWon)) ranks.set(history.gamesWon, new Map());
    if (!ranks.get(history.gamesWon)?.has(history.goalAverage)) {
      ranks.get(history.gamesWon)?.set(history.goalAverage, []);
    }
    ranks.get(history.gamesWon)?.get(history.goalAverage)?.push(playerName);
  });

  const rankByGameWon = Array.from(ranks.keys()).sort((a, b) => b - a);
  let globalRank = 1;
  const result: Record<string, number> = {};

  rankByGameWon.forEach((rank) => {
    const currentRank = ranks.get(rank);
    const rankByGoalAverage = Array.from(currentRank!.keys()).sort(
      (a, b) => b - a,
    );

    rankByGoalAverage.forEach((goalAverage) => {
      currentRank?.get(goalAverage)?.forEach((playerName) => {
        result[playerName] = globalRank;
      });
      globalRank += 1;
    });
  });

  return result;
}
