import { Configuration, PlayingHistory, Wave } from "../../../types";
import { Alert, Button, Card, Modal, Tooltip } from "@mui/material";
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { getPlayingHistory } from "../../../business/playing-history";
import TextField from "@mui/material/TextField";
import { waveGenerator } from "../../../business/wave-generator";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Game from "../../../business/game";

export default function TournamentComponent(props: {
  configuration: Configuration;
  next: (wave: Wave[]) => void;
}) {
  const [waves, setWaves] = useState<Wave[]>([
    waveGenerator(props.configuration),
  ]);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] =
    useState<boolean>(false);
  const [currentWaveIndex, setCurrentWaveIndex] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const removeLastWave = () => {
    setWaves(waves.slice(0, waves.length - 1));
    previousWave();
  };

  const isOnLastWave = () => {
    return currentWaveIndex === waves.length - 1;
  };

  const nextWave = () => {
    if (currentWaveIndex + 1 === waves.length) {
      setWaves([...waves, waveGenerator(props.configuration, waves)]);
    }

    setCurrentWaveIndex(currentWaveIndex + 1);
  };

  const previousWave = () => {
    if (currentWaveIndex === 0) return;
    setCurrentWaveIndex(currentWaveIndex - 1);
  };

  const setWinner = (game: Game, winner: Game["winner"]) => {
    game.winner = winner;
    setWaves([...waves]);
  };

  const isWinner = (game: Game, team: Game["winner"]): boolean => {
    return game.winner === team;
  };

  const setScore = (game: Game, team: "team1" | "team2", score: number) => {
    if (team === "team1") {
      game.team1Score = score;
      if (score < props.configuration.pointsPerWin) {
        game.team2Score = props.configuration.pointsPerWin;
        setWinner(game, "team2");
      } else {
        if (game.team2Score === props.configuration.pointsPerWin) {
          game.team2Score -= 1;
        }
        setWinner(game, "team1");
      }
    } else {
      game.team2Score = score;
      if (score < props.configuration.pointsPerWin) {
        game.team1Score = props.configuration.pointsPerWin;
        setWinner(game, "team1");
      } else {
        if (game.team1Score === props.configuration.pointsPerWin) {
          game.team1Score -= 1;
        }
        setWinner(game, "team2");
      }
    }
    setWaves([...waves]);
  };

  const everyGamesHasWinner = (): boolean => {
    return waves.every((wave) => wave.every((game) => !!game.winner));
  };

  const everyGamesHasWinnerForCurrentWave = (): boolean => {
    return waves[currentWaveIndex].every((game) => !!game.winner);
  };

  const getCurrentPlayingHistory = (): PlayingHistory | null => {
    let previousIndex = currentWaveIndex - 1;
    if (previousIndex < 0) return null;
    return getPlayingHistory(waves.slice(0, previousIndex + 1));
  };

  const previousPartners = (playerName: string) => {
    const playingHistory = getCurrentPlayingHistory();
    if (!playingHistory)
      return <span>Le joueur n'a pas eu de partenaires à ce stade.</span>;

    return (
      <span>
        Précédent partenaires de {playerName}
        <ul>
          {playingHistory[playerName].previousPartners.map((p, index) => (
            <li key={index}>{p.name}</li>
          ))}
        </ul>
      </span>
    );
  };
  return (
    <div className="Wave">
      <h1>PARTIE {currentWaveIndex + 1}</h1>
      <Button
        variant="contained"
        color="info"
        disabled={waves.length <= 1 || !isOnLastWave()}
        onClick={() => setIsModalOpen(true)}
      >
        Annuler la partie
      </Button>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="Players--nav--confirmation-modal">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Vous êtes en version gratuite vous ne pourrez pas sauvegarder ni
            exporter les résultats du concours.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Voulez vraiment annuler la partie ? Cette action est irréversible.
          </Typography>
          <section className="">
            <Button
              variant="contained"
              color="success"
              onClick={() => setIsModalOpen(false)}
            >
              Annuler
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                removeLastWave();
                setIsModalOpen(false);
              }}
            >
              Confirmer
            </Button>
          </section>
        </Box>
      </Modal>
      <Alert severity="info">
        {!props.configuration.goalAverage &&
          "Cliquer sur une équipe pour la définir gagnante."}
        {props.configuration.goalAverage &&
          "Saisir les points de l'équipe perdante."}
      </Alert>

      <section className="Wave--games">
        {waves[currentWaveIndex].map((game, index) => {
          return (
            <div className="Wave--games--game" key={index}>
              {props.configuration.goalAverage && (
                <TextField
                  id="filled-number"
                  label="Points"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event) => {
                    setScore(game, "team1", Number(event.target.value));
                  }}
                  value={
                    game.team1Score ??
                    Math.floor(props.configuration.pointsPerWin / 2)
                  }
                  variant="filled"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: props.configuration.pointsPerWin,
                    },
                  }}
                />
              )}
              <Card
                className={
                  isWinner(game, "team1")
                    ? "Wave--games--game--team Wave--games--game--team_winner"
                    : "Wave--games--game--team"
                }
                onClick={() =>
                  !props.configuration.goalAverage && setWinner(game, "team1")
                }
              >
                {game.team1.map((player) => {
                  return (
                    <p
                      key={player.name}
                      className="Wave--games--game--team--name"
                    >
                      <Tooltip title={previousPartners(player.name)}>
                        <span>{player.name}</span>
                      </Tooltip>
                    </p>
                  );
                })}
              </Card>

              <span className="Wave--games--game--VS">VS</span>

              <Card
                className={
                  isWinner(game, "team2")
                    ? "Wave--games--game--team Wave--games--game--team_winner"
                    : "Wave--games--game--team"
                }
                onClick={() =>
                  !props.configuration.goalAverage && setWinner(game, "team2")
                }
              >
                {game.team2.map((player) => {
                  return (
                    <p
                      key={player.name}
                      className="Wave--games--game--team--name"
                    >
                      <Tooltip title={previousPartners(player.name)}>
                        <span>{player.name}</span>
                      </Tooltip>
                    </p>
                  );
                })}
              </Card>
              {props.configuration.goalAverage && (
                <TextField
                  id="filled-number"
                  label="Points"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={
                    game.team2Score ??
                    Math.floor(props.configuration.pointsPerWin / 2)
                  }
                  onChange={(event) => {
                    setScore(game, "team2", Number(event.target.value));
                  }}
                  variant="filled"
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: props.configuration.pointsPerWin,
                    },
                  }}
                />
              )}
            </div>
          );
        })}
      </section>

      <section className="Wave--buttons">
        <Button
          variant="contained"
          color="info"
          className="Wave--buttons--button"
          disabled={!currentWaveIndex}
          onClick={previousWave}
        >
          Partie précédente
        </Button>
        <Button
          variant="contained"
          color="warning"
          className="Wave--buttons--button"
          disabled={!everyGamesHasWinner()}
          onClick={() => setIsConfirmDialogOpen(true)}
        >
          Terminer pour voir le classement
        </Button>

        <Button
          variant="contained"
          color="secondary"
          className="Wave--buttons--button"
          disabled={!everyGamesHasWinnerForCurrentWave()}
          onClick={nextWave}
        >
          Partie suivante
        </Button>
      </section>

      <Dialog
        onClose={() => setIsConfirmDialogOpen(false)}
        open={isConfirmDialogOpen}
        className="Wave--confirm-end"
      >
        <DialogTitle>Fin du tournoi</DialogTitle>
        <section className="Wave--confirm-end--text">
          <p>
            Vous êtes sur le point de mettre fin au tournoi. Si vous continuez,
            vous ne pourrez plus revenir en arrière et allez voir le classement.
          </p>
          <p>Êtes-vous sûr ?</p>
        </section>

        <section className="Wave--confirm-end--buttons">
          <Button
            variant="contained"
            color="secondary"
            className="Wave--confirm-end--buttons--button"
            onClick={() => setIsConfirmDialogOpen(false)}
          >
            Annuler
          </Button>

          <Button
            variant="contained"
            color="warning"
            className="Wave--confirm-end--buttons--button"
            onClick={() => props.next(waves)}
          >
            Voir le classement
          </Button>
        </section>
      </Dialog>
    </div>
  );
}
