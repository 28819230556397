import { Button, ButtonGroup } from "@mui/material";
import TextField from "@mui/material/TextField";
import React from "react";
import { Configuration, Mode } from "../../../types";

export function ConfigurationComponent(props: {
  onConfigurationChange: (configuration: Configuration) => void;
  configuration: Configuration;
  next: () => void;
}) {
  const onNext = () => {
    props.next();
    const mode = props.configuration.mode;
    if (mode === "1vs1") {
      props.onConfigurationChange({
        ...props.configuration,
        mode: "1vs1",
        teamPointeur: props.configuration.teamPointeur || [],
        teamTireur: undefined,
        teamMilieu: undefined,
      });
    } else if (mode === "2vs2") {
      props.onConfigurationChange({
        ...props.configuration,
        mode: "2vs2",
        teamPointeur: props.configuration.teamPointeur || [],
        teamTireur: props.configuration.teamTireur || [],
        teamMilieu: undefined,
      });
    } else {
      props.onConfigurationChange({
        ...props.configuration,
        mode: "3vs3",
        teamPointeur: props.configuration.teamPointeur || [],
        teamTireur: props.configuration.teamTireur || [],
        teamMilieu: props.configuration.teamMilieu || [],
      });
    }
  };
  const setMode = (mode: Mode) => {
    if (mode === "1vs1") {
      props.onConfigurationChange({
        ...props.configuration,
        mode: "1vs1",
      });
    } else if (mode === "2vs2") {
      props.onConfigurationChange({
        ...props.configuration,
        mode: "2vs2",
      });
    } else {
      props.onConfigurationChange({
        ...props.configuration,
        mode: "3vs3",
      });
    }
  };

  return (
    <div className="Configuration">
      <div>
        <section>
          <h2>Mode</h2>
          <p>
            Le mode permet de spécifier au générateur la taille de l'équipe
            qu'il doit favoriser.
          </p>

          <ButtonGroup
            variant="outlined"
            size="large"
            aria-label="large button group"
          >
            <Button
              onClick={() => setMode("1vs1")}
              variant={
                props.configuration.mode === "1vs1" ? "contained" : "outlined"
              }
            >
              1 VS 1
            </Button>
            <Button
              onClick={() => setMode("2vs2")}
              variant={
                props.configuration.mode === "2vs2" ? "contained" : "outlined"
              }
            >
              2 VS 2
            </Button>
            <Button
              onClick={() => setMode("3vs3")}
              variant={
                props.configuration.mode === "3vs3" ? "contained" : "outlined"
              }
            >
              3 VS 3
            </Button>
          </ButtonGroup>
        </section>

        <section>
          <h2>Voulez-vous jouer le goal-average en cas d'égalité ?</h2>
          <p>
            Différence entre le nombre de points marqués et le nombre de points
            encaissés.
          </p>
          <p>
            Si vous répondez non, vous aurez à uniquement désigner les équipes
            gagnantes pour chaque partie. Vous n'aurez pas à indiquer les
            points.
          </p>
          <ButtonGroup
            variant="outlined"
            size="large"
            aria-label="large button group"
          >
            <Button
              onClick={() =>
                props.onConfigurationChange({
                  ...props.configuration,
                  goalAverage: true,
                })
              }
              variant={
                !props.configuration.goalAverage ? "outlined" : "contained"
              }
            >
              Oui
            </Button>
            <Button
              onClick={() =>
                props.onConfigurationChange({
                  ...props.configuration,
                  goalAverage: false,
                })
              }
              variant={
                props.configuration.goalAverage ? "outlined" : "contained"
              }
            >
              Non
            </Button>
          </ButtonGroup>

          {props.configuration.goalAverage && (
            <div>
              {" "}
              <h3>Points pour victoire</h3>
              <p>
                Le nombre de points que doit atteindre une équipe pour gagner.
              </p>
              <TextField
                id="filled-number"
                label="Points"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) =>
                  props.onConfigurationChange({
                    ...props.configuration,
                    pointsPerWin: parseInt(event.target.value),
                  })
                }
                variant="filled"
                defaultValue={props.configuration.pointsPerWin}
                InputProps={{ inputProps: { min: 1, max: 13 } }}
              />
            </div>
          )}
        </section>
      </div>

      <div className="Configuration--action">
        <section>
          <Button variant="contained" color="success" onClick={onNext}>
            Suivant
          </Button>
        </section>
      </div>
    </div>
  );
}
