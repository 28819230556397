import { Configuration, Player, Team, Wave } from "../types";
import { validateConfiguration } from "./validator";
import {
  areTeamsAllTheSame,
  generateTeams,
  rebalanceNumberOfPlayerByTeam,
  removeSurplus,
} from "./team";
import { generateOpponents } from "./opponent-generator";
import { getPlayingHistory } from "./playing-history";
import { findOpponentIndexForTeamSurplus } from "./playing-history-analyzer";
import Game from "./game";

function generateOpponnentWhenLessThan3(
  removedPlayers: Player[],
): [Team, Team] {
  if (removedPlayers.length === 3) {
    return [[removedPlayers.pop()!], removedPlayers];
  } else if (removedPlayers.length === 2) {
    return [[removedPlayers.pop()!], [removedPlayers.pop()!]];
  } else {
    throw new Error("Cannot generate opponents with less than 2 players");
  }
}

function generate1vs1Wave(
  configuration: Configuration,
  previousWaves: Wave[],
): Wave {
  let availablePlayers = [...configuration.teamPointeur];
  const surplus = availablePlayers.flat().length % 2;
  const playingHistory = getPlayingHistory(previousWaves);
  const teamSurplus = removeSurplus(
    [availablePlayers],
    surplus === 1 ? 2 : surplus, // if the surplus is 1 we want to make a team with 2 players
    playingHistory,
    2,
  );
  const teams = generateTeams([availablePlayers], playingHistory);
  const opponents: [Team, Team][] = [];

  if (teamSurplus.length) {
    const index = findOpponentIndexForTeamSurplus(
      teamSurplus,
      teams,
      playingHistory,
      1,
    );
    opponents.push([teamSurplus, teams.splice(index, 1)[0]]);
  }

  opponents.push(...generateOpponents(teams, playingHistory));
  return opponents.map(([team1, team2]) => new Game(team1, team2));
}

function generate2vs2Wave(
  configuration: Configuration,
  previousWaves: Wave[],
): Wave {
  const teamPointer = [...configuration.teamPointeur!];
  const teamTireur = [...configuration.teamTireur!];
  const playingHistory = getPlayingHistory(previousWaves);

  const rebalancedTeams = rebalanceNumberOfPlayerByTeam(
    teamPointer,
    teamTireur,
  );
  const surplus = rebalancedTeams.flat().length % 4;
  const teamSurplus = removeSurplus(
    rebalancedTeams,
    surplus === 1 ? 3 : surplus,
    playingHistory,
    3,
  );
  const teams = generateTeams(rebalancedTeams, playingHistory);
  const wave: Wave = [];

  const opponents: [Team, Team][] = [];
  if (teamSurplus.length) {
    if (surplus === 1) {
      const index = findOpponentIndexForTeamSurplus(
        teamSurplus,
        teams,
        playingHistory,
        2,
      );
      opponents.push([teamSurplus, teams.splice(index, 1)[0]]);
    } else {
      opponents.push(generateOpponnentWhenLessThan3(teamSurplus));
    }
  }

  opponents.push(...generateOpponents(teams, playingHistory));
  opponents.forEach(([team1, team2]) => wave.push(new Game(team1, team2)));
  return wave;
}

function generate3vs3Wave(
  configuration: Configuration,
  previousWaves: Wave[],
): Wave {
  const teamPointer = [...configuration.teamPointeur];
  const teamTireur = [...configuration.teamTireur!];
  const teamMilieu = [...configuration.teamMilieu!];
  const playingHistory = getPlayingHistory(previousWaves);

  const rebalancedTeams = rebalanceNumberOfPlayerByTeam(
    teamPointer,
    teamTireur,
    teamMilieu,
  );
  const surplus = rebalancedTeams.flat().length % 6;
  let teamSurplus = removeSurplus(
    rebalancedTeams,
    surplus === 1 ? 7 : surplus,
    playingHistory,
    2,
  );

  const teams = generateTeams(rebalancedTeams, playingHistory);

  const opponents: [Team, Team][] = [];
  if (teamSurplus.length) {
    if (teamSurplus.length === 7) {
      opponents.push([
        [teamSurplus.pop()!, teamSurplus.pop()!, teamSurplus.pop()!],
        [teamSurplus.pop()!, teamSurplus.pop()!],
      ]);
      opponents.push([[teamSurplus.pop()!], [teamSurplus.pop()!]]);
    } else if (teamSurplus.length === 5) {
      opponents.push([[teamSurplus.pop()!, teamSurplus.pop()!], teamSurplus]);
    } else if (teamSurplus.length === 4) {
      opponents.push([[teamSurplus.pop()!, teamSurplus.pop()!], teamSurplus]);
    } else {
      opponents.push(generateOpponnentWhenLessThan3(teamSurplus));
    }
  }
  opponents.push(...generateOpponents(teams, playingHistory));
  return opponents.map(([team1, team2]) => new Game(team1, team2));
}

function duplicateOldWaveIfAllPossibleWavesAreGenerated(
  nextWave: Wave,
  previousWaves: Wave[],
  index = 0,
): Wave {
  if (
    index < 20 &&
    areTeamsAllTheSame(nextWave, previousWaves[previousWaves.length - 1])
  ) {
    const replacedWave =
      previousWaves[Math.floor(Math.random() * previousWaves.length)];
    return duplicateOldWaveIfAllPossibleWavesAreGenerated(
      replacedWave,
      previousWaves,
      index + 1,
    );
  } else {
    return nextWave.map((game) => new Game(game.team1, game.team2));
  }
}

export function waveGenerator(
  configuration: Configuration,
  previousWaves: Wave[] = [],
): Wave {
  validateConfiguration(configuration);
  let wave;
  if (configuration.mode === "1vs1") {
    wave = generate1vs1Wave(configuration, previousWaves);
  } else if (configuration.mode === "2vs2") {
    wave = generate2vs2Wave(configuration, previousWaves);
  } else {
    wave = generate3vs3Wave(configuration, previousWaves);
  }

  if (previousWaves.length > 0 && configuration.mode !== "1vs1") {
    wave = duplicateOldWaveIfAllPossibleWavesAreGenerated(wave, previousWaves);
  }

  return wave;
}
