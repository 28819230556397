import { Player, PlayingHistory, Team, Wave } from "../types";
import {
  findPartnersIndexesByPreferences,
  countHasPlayIn,
} from "./playing-history-analyzer";
import { getBiggestTeam, getSmallestTeam } from "./utils";

export function generateTeams(
  groupOfPlayers: Player[][],
  playingHistory: PlayingHistory,
): Team[] {
  const copyGroupOfPlayers = groupOfPlayers.map((group) => [...group]);
  const teams: Team[] = [];

  while (copyGroupOfPlayers[0].length > 0) {
    const team: Team = [];
    const partnersIndexes = findPartnersIndexesByPreferences(
      copyGroupOfPlayers,
      playingHistory,
    );
    partnersIndexes.forEach((partnerIndex, groupIndex) => {
      team.push(copyGroupOfPlayers[groupIndex].splice(partnerIndex, 1)[0]);
    });
    teams.push(team);
  }

  return teams;
}

export function removeSurplus(
  teams: Team[],
  surplus: number,
  playingHistory: PlayingHistory,
  mode: number,
): Player[] {
  const playersToRemove: Player[] = [];
  let previousBiggestTeam: Team | undefined = undefined;
  while (true) {
    if (playersToRemove.length === surplus) return playersToRemove;
    let biggestTeam = getBiggestTeam(...teams);
    // in the case when the biggest team is the same as the previous one, we remove the player from the next biggest team
    // this case can happen when the biggest team has the same number of players as the next biggest team
    if (previousBiggestTeam === biggestTeam) {
      biggestTeam = getBiggestTeam(
        ...teams.filter((team) => team !== biggestTeam),
      );
      if (biggestTeam.length === 0) biggestTeam = previousBiggestTeam;
    }
    let counts = biggestTeam.map((player) =>
      countHasPlayIn(player, playingHistory, mode),
    );
    const indexHasLessPlayIn = counts.indexOf(Math.min(...counts));

    playersToRemove.push(biggestTeam.splice(indexHasLessPlayIn, 1)[0]);
    previousBiggestTeam = biggestTeam;
  }
}

export function rebalanceNumberOfPlayerByTeam(...teams: Team[]): Team[] {
  const copiedTeams = [...teams];

  while (true) {
    const biggestTeam = getBiggestTeam(...copiedTeams);
    const smallestTeam = getSmallestTeam(...copiedTeams);
    if (biggestTeam.length - smallestTeam.length > 1) {
      smallestTeam.push(biggestTeam.pop()!);
    } else {
      return copiedTeams;
    }
  }
}

export function areTeamsAllTheSame(wave1: Wave, wave2: Wave): boolean {
  return wave1.every((game, index) =>
    wave2.some(
      (otherGame) =>
        (game.team1.every((player) =>
          otherGame.team1.some(
            (otherPlayer) => otherPlayer.name === player.name,
          ),
        ) &&
          otherGame.team1.length === game.team1.length) ||
        (game.team1.every((player) =>
          otherGame.team2.some(
            (otherPlayer) => otherPlayer.name === player.name,
          ),
        ) &&
          otherGame.team2.length === game.team1.length) ||
        (game.team2.every((player) =>
          otherGame.team1.some(
            (otherPlayer) => otherPlayer.name === player.name,
          ),
        ) &&
          otherGame.team1.length === game.team2.length) ||
        (game.team2.every((player) =>
          otherGame.team2.some(
            (otherPlayer) => otherPlayer.name === player.name,
          ),
        ) &&
          otherGame.team2.length === game.team2.length),
    ),
  );
}
