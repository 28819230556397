import { Configuration, Player, Team } from "../types";

export function getSmallestTeam(...teams: Team[]): Team {
  return teams.reduce((smallestTeam, team) => {
    return team.length < smallestTeam.length ? team : smallestTeam;
  });
}

export function getBiggestTeam(...teams: Team[]): Team {
  return teams.reduce((biggestTeam, team) => {
    return team.length > biggestTeam.length ? team : biggestTeam;
  }, []);
}

export function getAllPlayers(configuration: Configuration): Player[] {
  return [
    ...configuration.teamPointeur,
    ...(configuration.teamMilieu || []),
    ...(configuration.teamTireur || []),
  ].flat();
}
