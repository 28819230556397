import { PlayingHistory, Wave } from "../types";
import Game from "./game";

function buildHistory(game: Game, history: PlayingHistory): void {
  for (const teamName of ["team1", "team2"]) {
    const team = teamName === "team1" ? game.team1 : game.team2;
    const opponentTeam = teamName === "team1" ? game.team2 : game.team1;
    const teamScore = teamName === "team1" ? game.team1Score : game.team2Score;
    const opponentScore =
      teamName === "team1" ? game.team2Score : game.team1Score;

    for (const player of team) {
      history[player.name] ??= {
        previousPartners: [],
        previousOpponents: [],
        gamesWon: 0,
        previousGames: [],
        goalAverage: 0,
      };

      history[player.name].goalAverage +=
        (teamScore ?? 0) - (opponentScore ?? 0);
      history[player.name].gamesWon += game.winner === teamName ? 1 : 0;
      history[player.name].previousPartners.push(
        ...team.filter((p) => p.name !== player.name),
      );
      history[player.name].previousOpponents.push(...opponentTeam);
      history[player.name].previousGames.push(game);
    }
  }
}

export function getPlayingHistory(previousWaves: Wave[]): PlayingHistory {
  const history: PlayingHistory = {};

  for (const games of previousWaves) {
    for (const game of games) {
      buildHistory(game, history);
    }
  }

  return history;
}
