import React from "react";
import { Configuration } from "../../../types";
import RegisterPlayersComponent from "../../../shared-components/register-players";
import { Alert, Button, Modal } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { validateConfiguration } from "../../../business/validator";

export default function PlayersComponent(props: {
  configuration: Configuration;
  onChangeConfiguration: (configuration: Configuration) => void;
  previous: () => void;
  next: () => void;
}) {
  const [open, setOpen] = React.useState(false);

  const canNext = (): boolean => {
    try {
      validateConfiguration(props.configuration);
      return true;
    } catch (e) {
      return false;
    }
  };

  const getAllPlayers = () => {
    return [
      ...props.configuration.teamPointeur,
      ...(props.configuration.teamTireur || []),
      ...(props.configuration.teamMilieu || []),
    ];
  };

  return (
    <div className="Players">
      {!canNext() && (
        <Alert variant="outlined" severity="warning">
          Vous ne pouvez pas commencer la partie avec {getAllPlayers().length}{" "}
          joueurs.
        </Alert>
      )}

      <h2>Total Joueurs: {getAllPlayers().length}</h2>
      <div className="Players--register">
        <section>
          {props.configuration.mode === "1vs1" && (
            <h3>Joueur ({props.configuration.teamPointeur.length})</h3>
          )}

          {(props.configuration.mode === "2vs2" ||
            props.configuration.mode === "3vs3") && (
            <h3>Pointeur ({props.configuration.teamPointeur.length})</h3>
          )}

          <RegisterPlayersComponent
            onPlayersChange={(pointeur) => {
              props.onChangeConfiguration({
                ...props.configuration,
                teamPointeur: pointeur,
              });
            }}
            allPlayers={getAllPlayers()}
            players={props.configuration.teamPointeur}
          />
        </section>

        {props.configuration.mode === "3vs3" && (
          <section>
            <h3>Milieu ({props.configuration.teamMilieu?.length ?? 0})</h3>
            <RegisterPlayersComponent
              onPlayersChange={(middle) => {
                props.onChangeConfiguration({
                  ...props.configuration,
                  teamMilieu: middle,
                });
              }}
              allPlayers={getAllPlayers()}
              players={props.configuration.teamMilieu || []}
            />
          </section>
        )}
        {(props.configuration.mode === "3vs3" ||
          props.configuration.mode === "2vs2") && (
          <section>
            <h3>Tireur ({props.configuration.teamTireur!.length})</h3>
            <RegisterPlayersComponent
              onPlayersChange={(tireur) => {
                props.onChangeConfiguration({
                  ...props.configuration,
                  teamTireur: tireur,
                });
              }}
              allPlayers={getAllPlayers()}
              players={props.configuration.teamTireur!}
            />
          </section>
        )}
      </div>

      <div className="Players--nav">
        <Button variant="contained" color="info" onClick={props.previous}>
          Précédent
        </Button>
        <Button
          variant="contained"
          color="success"
          disabled={!canNext()}
          onClick={() => setOpen(true)}
        >
          Commencer
        </Button>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="Players--nav--confirmation-modal">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Vous êtes en version gratuite vous ne pourrez pas sauvegarder ni
              exporter les résultats du concours.
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
            ></Typography>
            <Typography variant="h6" component="h2">
              Envoyer un mail à mywallets@gmail.com pour essayer la version
              avancée.
            </Typography>
            <section className="Players--nav--confirmation-modal--confirmation">
              <Button
                variant="contained"
                color="success"
                onClick={() => props.next()}
                disabled={!canNext()}
              >
                Commencer
              </Button>
            </section>
          </Box>
        </Modal>
      </div>
    </div>
  );
}
