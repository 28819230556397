import * as React from "react";

export default function index() {
  return (
    <div className="LandingPageMobile">
      <div className="LandingPageMobile--logo">
        <img src="/assets/la-petanquerie.svg" alt="logo" width="300em" />
      </div>

      <div className="LandingPageMobile--title">
        <h1>
          Vous devez utiliser un ordinateur pour accéder à cette application
        </h1>
      </div>

      <section className="LandingPage--start--contact">
        <div>
          <a
            href="https://www.facebook.com/profile.php?id=61558268127332"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/assets/facebook.svg" alt="logo" width="80em" />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61558268127332"
            target="_blank"
            rel="noreferrer"
          >
            <span>Facebook</span>
          </a>
        </div>
        <div>
          <img src="/assets/mail.svg" alt="logo" width="80em" />
          <span>mywallets.app@gmail.com</span>
        </div>
      </section>
    </div>
  );
}
