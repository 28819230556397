import * as React from "react";
import { Button, useMediaQuery } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import Mobile from "./mobile";
import { useForm, ValidationError } from "@formspree/react";

function ContactForm() {
  const navigate = useNavigate();
  const [state, handleSubmit] = useForm("mgegpopk");

  return (
    <form
      onSubmit={async (e) => {
        await handleSubmit(e);
        navigate("/start");
      }}
      className="LandingPage--start--start--form"
    >
      <TextField
        id="email"
        type="email"
        name="email"
        label="Adresse mail"
        variant="outlined"
        className="LandingPage--start--start--form--mail"
        InputProps={{
          style: { borderRadius: "2em" },
        }}
      />
      <ValidationError prefix="Email" field="email" errors={state.errors} />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className="LandingPage--start--start--form--button"
        disabled={state.submitting}
        style={{
          backgroundColor: "#004AAD",
        }}
        sx={{
          "&.Mui-disabled": {
            color: "#c0c0c0",
          },
        }}
      >
        Commencer
      </Button>
    </form>
  );
}

export default function Index() {
  const height = useMediaQuery("(min-height:700px)");
  const width = useMediaQuery("(min-width:1080px)");
  const isComputerScreen = width && height;

  return isComputerScreen ? (
    <div className="LandingPage">
      <section className="LandingPage--start">
        <section className="LandingPage--start--logo">
          <img src="/assets/la-petanquerie.svg" alt="logo" width="300em" />
        </section>
        <section className="LandingPage--start--start">
          <h1>Générateur de mélées générales</h1>
          <h2>Pour vos tournois de Pétanque</h2>
          <ContactForm />
        </section>

        <section className="LandingPage--start--contact">
          <div>
            <a
              href="https://www.facebook.com/profile.php?id=61558268127332"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/assets/facebook.svg" alt="logo" width="80em" />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61558268127332"
              target="_blank"
              rel="noreferrer"
            >
              <span>Facebook</span>
            </a>
          </div>
          <div>
            <img src="/assets/mail.svg" alt="logo" width="80em" />
            <span>mywallets.app@gmail.com</span>
          </div>
        </section>
      </section>

      <section className="LandingPage--features"></section>
    </div>
  ) : (
    <Mobile />
  );
}
