import { Configuration, Wave } from "../../../types";
import { getPlayingHistory } from "../../../business/playing-history";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import React from "react";
import { CSVLink } from "react-csv";
import { getRanks } from "../../../business/rank";

export default function RankComponent(props: {
  configuration: Configuration;
  waves: Wave[];
}) {
  const playingHistory = getPlayingHistory(props.waves);
  const ranks = getRanks(props.waves);
  const rows = Object.entries(playingHistory).map(([playerName, history]) => ({
    name: playerName,
    gameWon: history.gamesWon,
    goalAverage: history.goalAverage,
    rank: ranks[playerName],
  }));
  rows.sort((a, b) => a.rank - b.rank);

  const headers = [
    { label: "Classement", key: "rank" },
    { label: "Nom", key: "name" },
    { label: "Victoires", key: "gameWon" },
    { label: "Goal Average", key: "goalAverage" },
  ];

  return (
    <div className="Rank">
      <h1>Classement des {props.waves.length} partie(s)</h1>
      <h2>
        <CSVLink filename={"classement.csv"} data={rows} headers={headers}>
          Télécharger le classement
        </CSVLink>
      </h2>
      <TableContainer component={Paper} className="Rank--table">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Classement</TableCell>
              <TableCell>Nom</TableCell>
              <TableCell align="right">Victoires</TableCell>
              {props.configuration.goalAverage && (
                <TableCell align="right">Goal Average</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {ranks[row.name]}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.gameWon}</TableCell>
                {props.configuration.goalAverage && (
                  <TableCell align="right">{row.goalAverage}</TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <section className="Rank--endGame">
        <Button
          variant="contained"
          color="secondary"
          className="Rank--endGame--button"
          onClick={() => window.location.reload()}
        >
          Revenir à l'accueil
        </Button>
      </section>
    </div>
  );
}
