import { Player, PlayingHistory, Team } from "../types";

export function generateProductCartesian(
  numberOfPlayerByTeam: number,
  numberOfTeams: number,
): number[][] {
  const initialArrays: number[][] = [];
  for (let i = 0; i < numberOfTeams; i++) {
    initialArrays.push([]);
    for (let j = 0; j < numberOfPlayerByTeam; j++) {
      initialArrays[i].push(j);
    }
  }

  const allCombinations: number[][] = [];
  const generate = (arrays: number[][], index: number, current: number[]) => {
    if (index === arrays.length) {
      allCombinations.push(current);
      return;
    }
    for (let i = 0; i < arrays[index].length; i++) {
      generate(arrays, index + 1, current.concat(arrays[index][i]));
    }
  };
  generate(initialArrays, 0, []);

  return allCombinations;
}

export function findPartnersIndexesByPreferences(
  groupOfPlayers: Player[][],
  playingHistory: PlayingHistory,
): number[] {
  const getPlayerHistory = (groupIndex: number, playerIndex: number) => {
    return playingHistory[groupOfPlayers[groupIndex][playerIndex].name];
  };
  const getAllPreviousPartners = (combination: number[]) => {
    return combination
      .map((playerIndex, groupIndex) => {
        return getPlayerHistory(groupIndex, playerIndex).previousPartners;
      })
      .flat();
  };

  const getAllPreviousOpponents = (combination: number[]) => {
    return combination
      .map((playerIndex, groupIndex) => {
        return getPlayerHistory(groupIndex, playerIndex).previousOpponents;
      })
      .flat();
  };

  if (groupOfPlayers.length === 1 || Object.keys(playingHistory).length === 0) {
    return new Array(groupOfPlayers.length).fill(0);
  }

  const allCombinations = generateProductCartesian(
    groupOfPlayers[0].length,
    groupOfPlayers.length,
  );

  let combination = allCombinations.find((combination) => {
    const allPreviousPartners = getAllPreviousPartners(combination);
    const allPreviousOpponents = getAllPreviousOpponents(combination);

    // check if all players have never played with each other or against each other
    return combination.every((playerIndex, groupIndex) => {
      const player = groupOfPlayers[groupIndex][playerIndex];
      return (
        !allPreviousPartners.includes(player) &&
        !allPreviousOpponents.includes(player)
      );
    });
  });

  if (combination) return combination;

  // find composition of team that never played with each other
  combination = allCombinations.find((combination) => {
    const allPreviousPartners = getAllPreviousPartners(combination);
    return combination.every((playerIndex, groupIndex) => {
      return !allPreviousPartners.includes(
        groupOfPlayers[groupIndex][playerIndex],
      );
    });
  });
  if (combination) return combination;

  // find composition at least one player that never played with each other
  combination = allCombinations.find((combination) => {
    const allPreviousPartners = getAllPreviousPartners(combination);
    return combination.some((playerIndex, groupIndex) => {
      return !allPreviousPartners.includes(
        groupOfPlayers[groupIndex][playerIndex],
      );
    });
  });
  if (combination) return combination;

  // random combination
  return allCombinations[Math.floor(Math.random() * allCombinations.length)];
}

export function findTeamOpponentIndexByPreferences(
  team: Team,
  teams: Team[],
  playingHistory: PlayingHistory,
): number {
  if (Object.keys(playingHistory).length === 0) {
    return Math.floor(Math.random() * teams.length);
  }

  const allPreviousOpponents = team
    .map((player) => {
      return playingHistory[player.name]?.previousOpponents;
    })
    .flat();
  const allPreviousPartners = team
    .map((player) => {
      return playingHistory[player.name]?.previousPartners;
    })
    .flat();
  let targetedTeam = teams.findIndex((t) => {
    return t.every(
      (player) =>
        !allPreviousOpponents.includes(player) &&
        !allPreviousPartners.includes(player),
    );
  });
  if (targetedTeam !== -1) return targetedTeam;

  targetedTeam = teams.findIndex((t) => {
    return t.every((player) => !allPreviousPartners.includes(player));
  });
  if (targetedTeam !== -1) return targetedTeam;
  return Math.floor(Math.random() * teams.length);
}

export function findOpponentIndexForTeamSurplus(
  team: Team,
  teams: Team[],
  playingHistory: PlayingHistory,
  mode: number,
): number {
  const deepCopyTeams = teams.map((team) => [...team]);
  const teamIndexCanEventuallyPlay: number[] = [];
  deepCopyTeams.forEach((_, index) => {
    const potentialIndex =
      findTeamOpponentIndexByPreferences(
        team,
        deepCopyTeams.slice(index, deepCopyTeams.length),
        playingHistory,
      ) + index;
    const hasAlreadyPlayedInOrAgainst = teams[potentialIndex].some((player) => {
      return playingHistory[player.name]?.previousGames.some((game) => {
        // we check if the opponent team has already played in or against a team with a different mode
        if (game.team1.length !== mode || game.team2.length !== mode) {
          teamIndexCanEventuallyPlay.push(index);
        }
        // we want to favor teams that have not played in or against a team with a different mode
        return game.team1.length !== mode && game.team2.length !== mode;
      });
    });
    if (hasAlreadyPlayedInOrAgainst) return index;
  });

  if (teamIndexCanEventuallyPlay.length > 0) {
    return teamIndexCanEventuallyPlay[
      Math.floor(Math.random() * teamIndexCanEventuallyPlay.length)
    ];
  } else {
    return Math.floor(Math.random() * teams.length);
  }
}

export function countHasPlayIn(
  player: Player,
  playingHistory: PlayingHistory,
  n: number,
): number {
  if (Object.keys(playingHistory).length === 0) return 0;

  return playingHistory[player.name].previousGames.filter((game) => {
    return (
      (game.team1.find((p) => player.name === p.name) &&
        game.team1.length === n) ||
      (game.team2.find((p) => player.name === p.name) &&
        game.team2.length === n)
    );
  }).length;
}
