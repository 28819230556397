import { Team } from "../types";

export default class Game {
  public team1: Team;
  public team2: Team;
  public team1Score: number | undefined;
  public team2Score: number | undefined;
  public winner: "team1" | "team2" | undefined;

  constructor(team1: Team, team2: Team) {
    this.team1 = team1;
    this.team2 = team2;
  }
}
