import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { Player, Team } from "../../types";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

export default function RegisterPlayersComponent(props: {
  onPlayersChange: (players: Team) => void;
  players: Player[];
  allPlayers: Player[];
}) {
  const [nextPlayerName, setNextPlayerName] = useState<string | undefined>("");
  const [isOnErrorPlayerName, setOnErrorPlayerName] = useState<string>("");

  const toLowerCase = (aString: string): string => {
    return aString.trim().toLowerCase();
  };

  const add = (nextPlayerName: string | undefined) => {
    if (nextPlayerName) {
      if (
        props.allPlayers.find(
          (player) => toLowerCase(player.name) === toLowerCase(nextPlayerName),
        )
      ) {
        setOnErrorPlayerName("Ce joueur existe déjà");
        return;
      }

      const nextPlayers = [...props.players, { name: nextPlayerName }];
      setOnErrorPlayerName("");
      setNextPlayerName("");
      props.onPlayersChange(nextPlayers);
    } else {
      setOnErrorPlayerName("Le nom du joueur est obligatoire");
    }
  };

  const handleKeypress = (e: any) => {
    if (e.key === "Enter") add(nextPlayerName);
  };

  const remove = (playerName: string) => {
    const nextPlayers = props.players.filter(
      (player) => toLowerCase(player.name) !== toLowerCase(playerName),
    );
    props.onPlayersChange(nextPlayers);
  };

  return (
    <section className="RegisterPlayers">
      <div className="RegisterPlayers--add">
        <TextField
          onChange={(event) => setNextPlayerName(event.target.value as string)}
          onKeyDown={handleKeypress}
          value={nextPlayerName}
          id="outlined-basic"
          label="Nom du Joueur"
          variant="outlined"
          className="RegisterPlayers--add--input"
          error={!!isOnErrorPlayerName}
          helperText={isOnErrorPlayerName}
        />
        <Button
          variant="contained"
          color="success"
          onClick={() => add(nextPlayerName)}
          className="RegisterPlayers--add--button"
        >
          Ajouter
        </Button>
      </div>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 380 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell key="playerName" align="left">
                  Joueurs
                </TableCell>
                <TableCell key="actions" align="right">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.players.map((player) => {
                return (
                  <TableRow hover role="row" tabIndex={-1} key={player.name}>
                    <TableCell
                      key={player.name}
                      align="left"
                      className="RegisterPlayers--player"
                    >
                      {player.name}
                    </TableCell>
                    <TableCell key={`${player.name}-delete`} align="right">
                      <IconButton
                        color="warning"
                        onClick={() => remove(player.name)}
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </section>
  );
}
