import React from "react";

import { Configuration, Wave } from "../../types";
import { ConfigurationComponent } from "./configuration";
import PlayersComponent from "./players";
import TournamentComponent from "./tournament";
import RankComponent from "./rank";

export default function Home() {
  const [configuration, setConfiguration] = React.useState<Configuration>({
    mode: "2vs2",
    goalAverage: false,
    pointsPerWin: 13,
    teamPointeur: [],
    teamTireur: [],
  });
  const [waves, setWaves] = React.useState<Wave[]>([]);
  const [form, setForm] = React.useState<
    "configuration" | "players" | "tournament" | "rank"
  >("configuration");

  const getForm = () => {
    if (form === "configuration") {
      return (
        <div className="Home--configuration">
          <h1 className="Home--title">Générateur mélée tournante</h1>
          <ConfigurationComponent
            next={() => setForm("players")}
            configuration={configuration}
            onConfigurationChange={(configuration) => {
              setConfiguration(configuration);
            }}
          />
        </div>
      );
    } else if (form === "players") {
      return (
        <PlayersComponent
          configuration={configuration!}
          onChangeConfiguration={setConfiguration}
          previous={() => setForm("configuration")}
          next={() => setForm("tournament")}
        />
      );
    } else if (form === "tournament") {
      return (
        <TournamentComponent
          configuration={configuration!}
          next={(waves) => {
            setWaves(waves);
            setForm("rank");
          }}
        />
      );
    } else if (form === "rank") {
      return <RankComponent configuration={configuration} waves={waves} />;
    }
  };
  return <div className="Home">{getForm()}</div>;
}
