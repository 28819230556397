import { PlayingHistory, Team } from "../types";
import { findTeamOpponentIndexByPreferences } from "./playing-history-analyzer";

export function generateOpponents(
  teams: Team[],
  playingHistory: PlayingHistory,
): [Team, Team][] {
  let opponents: [Team, Team][] = [];

  while (teams.length > 1) {
    const team1 = teams.shift()!;
    const team2Index = findTeamOpponentIndexByPreferences(
      team1,
      teams,
      playingHistory,
    );

    opponents.push([team1, teams.splice(team2Index, 1)[0]]);
  }

  return opponents;
}
